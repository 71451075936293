section.dates {

    .accordion {
        list-style-type: none;
        margin-left: 0;
    }

    .accordion[disabled] .accordion-title {
        cursor: not-allowed;
    }

    .accordion.is-active {
    }

    .accordion-item:first-child> :first-child {
        border-radius: 0 0 0 0;
    }

    .accordion-item:last-child> :last-child {
        border-radius: 0 0 0 0;
    }

    .accordion-title {
        position: relative;
        display: block;
        opacity: 1;
        text-transform: uppercase;
        font-style: italic;
    }

    :last-child:not(.is-active)>.accordion-title {}

    .accordion-title:hover,
    .accordion-title:focus {}


    .accordion-content {
        display: none;
        padding: 1rem;
    }

}