.button {
    cursor: pointer;
    background-repeat: no-repeat;
    padding: 0.85rem 1rem;

    &.hollow {
        color: $white;
        background: transparent;
        border-color: $white;
        border-width: 1px;

        &:hover {
            background-color: $white;
            color: get-color(primary) !important;
            border-color: $white;
        }

        &.black {
            background-color: transparent;
            color: $black;
            border: solid 1px $black;

            &:hover {
                background-image: $black;
                color: $black;
                border-color: $black;
            }
        }
    }


    &.black {
        background-color: $black;
        color: $white;
        border: none;

        &:hover {
            animation-duration: 30s;
            background-image: get-color(primary);
            color: $black;
        }
    }

    &.white {
        background-color: $white;
        color: $black;
        border: none;

        &:hover {
            background-color: $black;
            animation: background-color;
            color: get-color(primary);
        }
    }

    &.large {
        font-weight: bold;
    }

    @include breakpoint(medium) {
        font-size: rem-calc(18);
    }
}

.input-group {
    .input-group-field {
        .input-group-field {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
        }
    }

    .input-group-button {
        .button {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
        }
    }
}