.main-nav {
    width: 100%;
    position: fixed;
    z-index: 100;

    .grid-container {
        padding: 0;

        .top-bar {
            padding-left: 0;
            @include top-bar-unstack;

            .top-bar-left,
            .top-bar-right {
                .menu {
                    li {
                        a {
                            transition: color 500ms ease;
                        }
                    }
                }
            }

            .top-bar-title {
                text-align: center;
                opacity: 0;
                letter-spacing: 0.2rem;
            }

            .top-bar-title {
                text-align: center;
                padding-left: 1rem;

            }

            .menu {
                a {
                    transition-delay: 1s;
                    transition: fill 500ms ease;
                    color: $white;
                    padding: .7rem 1rem;

                    &:hover {
                        color: get-color(primary);
                    }

                    &.is-active {
                        font-weight: bold;
                    }
                }
            }

            .top-bar-left {
                .dropdown.menu {
                    li.is-dropdown-submenu-parent {
                        a {
                            padding: .7rem 1rem;
                        }

                        a::after {
                            transition: border-color 500ms ease;
                            border-color: $white transparent transparent;
                        }

                        a:hover {
                            &::after {
                                transition: border-color 500ms ease;
                                border-color: get-color(primary) transparent transparent;
                            }
                        }
                    }

                    .is-dropdown-submenu {
                        background-color: $white;
                        border: none;

                        li {
                            a {
                                color: $black;

                                &:hover {
                                    transition: border-color 500ms ease;
                                    background-color: get-color(primary);
                                }

                            }
                        }
                    }
                }

                .langswitcher {
                    text-transform: uppercase;
                }
            }

            .top-bar-right {

                .contact {
                    border: solid 0.5px $white;
                }
            }
        }
    }

    &.onTop {

        .grid-container {
            .top-bar {
                transition: background-color 200ms ease;
                background-color: transparent;

                .top-bar-title {
                    opacity: 0
                }

                .top-bar-left,
                .top-bar-right {
                    .menu {
                        li {

                            a,
                            button {
                                &.is-active {
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.notOnTop {
        background-color: $white;
        border-bottom: solid 0.5px $light-gray;

        .grid-container {
            .top-bar {
                background-color: $white;
                transition-delay: 1s;
                transition: background-color 500ms ease;

                .top-bar-left,
                .top-bar-right {
                    .menu {
                        li {

                            a,
                            button {
                                transition: color 500ms ease;
                                color: $black;

                                &:hover {
                                    color: get-color(primary);
                                }

                                &.is-active {
                                    font-weight: bold;
                                }
                            }
                        }

                        &.dropdown {
                            li.is-dropdown-submenu-parent {
                                a::after {
                                    border-color: $black transparent transparent;
                                }

                                a:hover {
                                    color: get-color(primary);

                                    &::after {
                                        border-color: get-color(primary) transparent transparent;
                                    }
                                }
                            }

                            .is-dropdown-submenu {
                                background-color: $white;
                                border: solid 0.5px $light-gray;

                                li {
                                    a {
                                        color: $black;

                                        &:hover {
                                            color: $black;
                                            transition: border-color 500ms ease;
                                            background-color: get-color(primary);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .hollow {
                        border: solid 0.5px $black;
                        transition-delay: 1s;
                        transition: background-image 500ms ease;
                        background: transparent;

                        &:hover {
                            transition: background-image 200ms ease;
                            // background-image: linear-gradient(95deg, rgba(get-color(primary), 1), rgba(get-color(secondary), 1));
                            border-color: transparent;
                            color: $white;
                        }
                    }
                }

                .top-bar-title {
                    background-image: none;
                    opacity: 1;
                }
            }
        }
    }



    &.gray {
        .grid-container {
            .top-bar {
                .top-bar-title {
                    opacity: 1;
                }

                .menu {
                    a {
                        transition-delay: 1s;
                        transition: fill 500ms ease;
                        color: $black;

                        &:hover {
                            color: get-color(primary);
                        }
                    }

                    .hollow {
                        border: solid 0.5px $black;
                        color: $black;

                        &:hover {
                            border-color: transparent;
                            transition: background-image 200ms ease;
                            // background-image: linear-gradient(95deg, rgba(get-color(primary), 1), rgba(get-color(secondary), 1));
                        }
                    }
                }

                .top-bar-left {
                    .dropdown.menu {
                        li.is-dropdown-submenu-parent {
                            a::after {
                                border-color: $black transparent transparent;
                            }

                            a:hover {
                                &::after {
                                    border-color: get-color(primary) transparent transparent;
                                }
                            }
                        }
                    }
                }

                &.onTop {
                    .grid-container {
                        .top-bar-right {

                            .hollow {
                                color: $black;

                                &:hover {
                                    transition: background-image 200ms ease;
                                    // background-image: linear-gradient(95deg, rgba(get-color(primary), 1), rgba(get-color(secondary), 1));
                                    border-color: transparent;
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(small only) {
        border: none;

        .grid-container {
            .top-bar {
                padding: 0;

                .top-bar-right {
                    padding-right: .5rem;
                }

                .top-bar-title {
                    // height: rem-calc(80);
                    // width: rem-calc(80);

                    svg {
                        path {
                            transition-delay: 1s;
                            transition: fill 500ms ease;
                            fill: $primary-color;
                        }
                    }

                }
            }
        }

        &.onTop {
            .grid-container {
                .top-bar {
                    .top-bar-title {

                        svg {
                            path {
                                transition-delay: 1s;
                                transition: fill 500ms ease;
                                fill: $primary-color;
                            }
                        }
                    }
                }
            }
        }

        &.notOnTop {
            .grid-container {
                .top-bar {
                    .top-bar-title {
                        background-image: none;
                        background-color: $white;

                        svg {
                            path {
                                transition-delay: 1s;
                                transition: fill 500ms ease;
                                fill: $primary-color
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(medium) {

        .grid-container {

            .top-bar {
                padding-top: 0;
                padding-bottom: 0;
                font-size: rem-calc(24);

                .top-bar-title {
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-top: rem-calc(16);
                    padding-bottom: rem-calc(16);
                }

                .button {
                    margin-left: 1rem;
                    font-size: rem-calc(24);
                }
            }
        }

        &.gray {
            .grid-container {
                .top-bar-title {
                    svg {
                        path {
                            transition-delay: 1s;
                            transition: fill 500ms ease;
                            fill: $primary-color !important;
                        }
                    }
                }
            }
        }

        &.onTop {
            .grid-container {
                .top-bar-title {
                    svg {
                        path {
                            transition-delay: 1s;
                            transition: fill 500ms ease;
                            fill: $white
                        }
                    }
                }
            }
        }

        &.notOnTop {
            .grid-container {
                .top-bar-title {
                    svg {
                        path {
                            transition-delay: 1s;
                            transition: fill 500ms ease;
                            fill: $primary-color
                        }
                    }
                }
            }
        }
    }
}