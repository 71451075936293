@keyframes fade-in {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes fade-out {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@keyframes fade-in-07 {
    0% {
        opacity: 0.7;
    }

    50% {
        opacity: 1;
    }

}

@keyframes fade-out-07 {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.7;
    }
}


@keyframes gradient-scroll {
    0% {
        background-position: 0% 0%
    }

    50% {
        background-position: 100% 100%
    }

    100% {
        background-position: 0% 0%
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    50% {
        -webkit-transform: translate(0, 10px);
        transform: translate(0, 10px);
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@keyframes rotate-45-in {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(-45deg);
    }
}

@keyframes rotate-45-out {
    0% {
        transform: rotate(-45deg);
    }

    100% {
        transform: rotate(0);
    }
}


@keyframes slide-right-in {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(8px);
    }
}

@keyframes slide-right-out {
    0% {
        transform: translateX(8px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slide-in-bottom {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes slide-out-bottom {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(100%);
    }
}



@keyframes zoom-fade-in {
    0% {
        opacity: 0;
        transform: scale(1);
    }

    100% {
        opacity: 1;
        transform: scale(1.2);
    }
}

@keyframes zoom-fade-out {
    0% {
        opacity: 1;
        transform: scale(1.2);
    }

    100% {
        opacity: 0;
        transform: scale(1);
    }
}


@keyframes zoom-in {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.2);
    }
}


@keyframes zoom-out {
    0% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes zoom-in-gradient {
    0% {
        transform: scale(1);
        background-position: 0% 0%
    }

    10% {
        transform: scale(1.1);
    }

    50% {
        background-position: 100% 100%
    }

    100% {
        transform: scale(1.1);
        background-position: 0% 0%;

    }
}

@keyframes grow {
    0% {
        width: 3%;
        left: -2%;
        right: 98%;
    }

    50% {
        width: 102%;
        left: -1%;
        right: -1%;
    }

    98% {
        width: 4%;
        right: -2%;
        left: 98%;
    }

    100% {
        width: 2%;
        right: -1%;
        left: 99%;
    }
}


@keyframes vehicle-rotate-in {
    0% {
        transform: rotate(6deg);
        transform-origin: 200% 100%;
        opacity: 0.9;
    }

    100% {
        transform: rotate(0);
        opacity: 1;
        transform-origin: 200% 100%;
    }
}

@keyframes vehicle-rotate-out {
    0% {
        transform-origin: 200% 100%;
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: rotate(-6deg);
        transform-origin: 200% 100%;
    }
}


@keyframes text-rotate-in {
    0% {
        transform: rotate(6deg);
        transform-origin: -200% 100%;
        opacity: 0.9;
    }

    100% {
        transform: rotate(0);
        transform-origin: -200% 100%;
        opacity: 1;
    }

}

@keyframes text-rotate-out {
    0% {
        transform: rotate(0);
        transform-origin: -200% 100%;
        opacity: 1;
    }

    100% {
        transform: rotate(-6deg);
        transform-origin: -200% 100%;
        opacity: 0;
    }



}




[data-aos="video-icon"] {
    transition-property: transform;
    transform: translateY(0%);

    &.aos-animate {
        transform: translateY(50%);
    }
}