$grid-margin-count: 20;
$grid-base-margin: 8px;

$spacing-types: (margin, padding) !default;
$spacing-dir: (top, bottom) !default;


@include -zf-each-breakpoint {
    @include breakpoint($-zf-size) {
        @each $spacing in $spacing-types {
            @each $dir in $spacing-dir {
                @for $i from 0 through $grid-margin-count {
                    .#{$-zf-size}-#{$spacing}-#{$dir}-#{$i} {
                        #{$spacing}-#{$dir}: rem-calc($i * $grid-base-margin);
                    }
                }
            }
            @for $i from 0 through $grid-margin-count {
                .#{$-zf-size}-#{$spacing}-#{$i} {
                    #{$spacing}-top: rem-calc($i * $grid-base-margin);
                    #{$spacing}-bottom: rem-calc($i * $grid-base-margin);
                }
            }
        }
    }
}
