header.header {
    background-color: $white;

    .grid-container {
        padding: 0;

        .header-conatiner {
            position: relative;


            .video-img-container {
                position: relative;
                padding-bottom: rem-calc(80);

                video {
                    max-height: calc(100vh - 80px);
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                img {
                    max-height: calc(100vh - 80px);
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                .header-content {
                    position: absolute;
                    z-index: 3;
                    top: 42%;

                    .title {
                        margin-bottom: 1rem;

                        svg {
                            width: 100%;
                            height: auto;

                            path {
                                fill: #fff;
                            }

                        }
                    }

                    .content {
                        color: $white;

                    }

                    .slogan {
                        letter-spacing: 2px;
                    }
                }

                svg.overlay {
                    position: absolute;
                    right: 0;
                    bottom: rem-calc(78);
                    width: calc(100% + 1px);
                    height: 1.5rem;

                    path {
                        fill: $white;
                        stroke: none;
                    }
                }
            }
        }
    }

    @include breakpoint(small only) {
        .grid-container {
            .header-conatiner {
                .video-img-container {
                    min-height: 100vh;
                    // min-height: calc(100vh - 80px);
                    padding-bottom: 0;

                    svg.overlay {
                        display: none;
                    }

                    .title {
                        bottom: rem-calc(36);
                    }

                    .header-image {
                        height: 100vh;

                        img {
                            max-height: 100vh;
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .video-container {
                        max-height: 100vh;
                        width: 100%;
                        height: 100%;
                        position: relative;

                        .play-button {
                            position: absolute;
                            z-index: 30;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);

                            &:hover {
                                svg {
                                    animation: zoom-in 300ms ease both;

                                }
                            }

                            svg {
                                width: 4rem;
                                height: auto;

                            }
                        }

                        video {
                            // width: 100%;
                            // height: auto;
                            // z-index: 1;
                            max-height: 100vh;
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }


                    }
                }

                // &:after {
                //     position: absolute;
                //     content: '';
                //     width: 100%;
                //     height: 100%;
                //     top: 0;
                //     left: 0;
                //     background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
                // }
            }
        }

    }

    @include breakpoint(medium only) {
        .grid-container {}
    }

    @include breakpoint(large) {
        .grid-container {}
    }
}