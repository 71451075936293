.off-canvas {
    background: get-color(secondary);

    a {
        color: $white;
        &:hover{
            color: lighten($anchor-color-hover, 20%);
        }
        &:focus {
            outline: none;
        }
    }

    .top-bar {
        @include top-bar-unstack;

        .top-bar-left,
        .top-bar-right {

            .logo {
                width: auto;
                height: rem-calc(65);
                path{
                    fill: $white;
                }
            }
        }
    }

    .menu-text {
        li {
            a {
                padding-left: 0 !important;
            }
        }
    }

    .footer-accordion {
        a.accordion-title {
            h2 {
                margin-top: 0;
            }
        }
    }
}


// .off-canvas {
//     background: get-color(secondary);
//     padding: 1rem;
//     z-index: z(thing);

//     a {
//         &:focus {
//             outline: none;
//         }
//     }

//     svg.logo {
//         width: 100%;
//         height: auto;

//         path {
//             fill: #fff;
//         }
//     }

//     .top-bar {
//         @include top-bar-unstack;

//         .top-bar-left,
//         .top-bar-right {

//             .logo {
//                 width: auto;
//                 height: rem-calc(65);
//             }
//         }
//     }

//     .menu {
//         li {
//             a {
//                 color: $white;
//                 // font-size: rem-calc(32);
//                 background-color: transparent;
//                 transition: background-color 0.25s ease-out, color 0.25s ease-out;

//                 &:hover {
//                     transition: background-color 0.25s ease-out, color 0.25s ease-out;
//                     color: $primary-color;
//                     background-color: $white;
//                 }

//                 &.button {
//                     // margin-top: 4rem;
//                 }
//             }
//         }
//     }
// }


// .off-canvas-content {
//     overflow-x: auto;

//     &.is-open-right {
//         overflow-y: hidden;
//         max-height: 100vh;
//     }
// }