section.video {
    position: relative;
    background-color: $light-gray;

    .overlay {
        position: absolute;
        bottom: 100%;
        z-index: 10;
        width: 100%;

        polygon {
            fill: $light-gray;
        }
    }
}