@font-face {
    font-family: 'OpenSans';
    src: url('../font/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('../font/OpenSans-Semibold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'FredokaOne';
    src: url('../font/FredokaOne-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.subline {
    font-size: rem-calc(16);
    font-weight: 600;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: $dark-gray;
}

.white {
    color: $white;
}


span,
a {
    line-height: 1.5;
}

address {
    font-style: normal !important;
    line-height: rem-calc(39px);
}

a.button,
button {

    @include breakpoint(small only) {
        margin-top: 2rem;
    }

}

.small-text-center {

    @include breakpoint(small only) {
        text-align: center
    }
}

.text-white{
    color: $white;
}


a {
    svg.link-arrow {
        animation: slide-right-out 300ms ease both;

        path,
        polyline {
            stroke: get-color(primary);
        }
    }

    &:hover {
        svg.link-arrow {
            animation: slide-right-in 300ms ease both;

            path,
            polyline {
                stroke: get-color(secondary);
            }
        }
    }
}