// Dev
._red {
    background-color: rgba(red, 0.2);
    border: dashed 1px rgba(red, 0.8);
}

._green {
    background-color: rgba(green, 0.2);
    border: dashed 1px rgba(green, 0.8);
}

._blue {
    background-color: rgba(blue, 0.2);
    border: dashed 1px rgba(blue, 0.8);
}

._yellow {
    background-color: rgba(yellow, 0.2);
    border: dashed 1px rgba(yellow, 0.8);
}