.footer {
    // height: rem-calc(442);
    // height: rem-calc(420);
    position: relative;

    width: 100%;
    // overflow: hidden;

    svg.overlay {
        top: calc(-1.5rem + 1px);

        path {
            fill: $secondary-color;
        }
    }

    .contact {
        background-color: $secondary-color;

        .grid-container {
            position: relative;
            // overflow: hidden;
            color: $white;

            .content {
                position: relative;
                z-index: 20;
            }
        }

        svg.overlay {
            position: absolute;
            right: 0px;
            top: -50px;

            path {
                fill: $dark-gray;
            }
        }

        .css-overlay {
            position: absolute;
            right: 0;
            bottom: 100%;
            height: 50px;
            background-color: $dark-gray;
            z-index: 4;

            &::before {
                position: absolute;
                content: '';
                width: 0;
                height: 100px;
                right: 100%;
                top: 0;
                border-right: 50px solid $dark-gray;
                border-left: 50px solid transparent;
                border-bottom: 100px solid transparent;
                border-top: 100px solid transparent;
            }

            &::after {
                position: absolute;
                content: '';
                background-color: $dark-gray;
                left: 100%;
                width: 50vw;
                height: 100%;
            }
        }
    }

    .footer-menu {
        padding-top: rem-calc(36);
        padding-bottom: rem-calc(36);
        background-color: $white;


        svg {
            width: 100%;
            height: auto;
        }

        .menu {


            li {
                &:last-child {
                    a {
                        // padding-right: 0;
                    }
                }

                a {
                    color: $black;

                    &:hover {
                        color: get-color(primary)
                    }
                }
            }
        }
    }

    @include breakpoint(small only) {
        height: auto;

        .grid-container {
            padding: 0;
        }

        .contact {
            div {

                h2,
                p,
                a {
                    max-width: 83.3333333333%
                }

                &.small-green {
                    background-color: get-color(primary);
                }

                .content {
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }

        svg.overlay {
            display: none;
        }

        .footer-menu {
            padding-bottom: 0;

            .menu {
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
            }
        }
    }

    @include breakpoint(medium) {

        .contact {
            .grid-container {
                padding-bottom: rem-calc(40);
                padding-top: 2rem;
            }
        }

        .footer-menu {
            .menu {
                -webkit-box-pack: end;
                -webkit-justify-content: flex-end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                padding-right: 0.5rem;
            }
        }
    }
}