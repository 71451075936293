body {
    min-height: 100%;
    max-width: 100vw;
    overflow-x: hidden;
    // font-size: 1.3em;
    background-image: linear-gradient(0deg, $white, $secondary-color);


    main {
        background-color: $white;
        min-height: 100vh;
        flex: 1;
    }

    .off-canvas-content {
        min-height: 100%;
        max-width: 100vw;
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        min-height: 100vh;
        flex-direction: column;

    }

    section,
    header {
        background-color: $white;

        &.gray {
            background-color: $light-gray;
        }

        &.black {
            background-color: $black;
        }

        &.blue {
            background-color: lighten($primary-color, 20%);
        }
    }

    video {
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        cursor: pointer;
    }

    .video-container {
        cursor: pointer;
    }

    svg.overlay {
        position: absolute;
        width: calc(100% + 1px);
        height: 1.5rem;

        polygon,
        path {
            fill: $white;
            stroke: none;
        }
    }

    // @include breakpoint(medium) {

    //     main {
    //         margin-top: rem-calc(96);
    //     }

    //     header {
    //         margin-top: rem-calc(-96);
    //     }
    // }
}